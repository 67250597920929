import React, { useState, useMemo } from "react"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { Link, graphql } from "gatsby"
import slugify from "slugify"

const Shop = (props) => {
  const products = props.data.allEcwidProducts.nodes
  const categories = props.data.allEcwidCategories.nodes

  const [activeCategory, setActiveCategory] = useState(categories[0])

  const datatable = useMemo(() => {
    let computedData = products

    if (activeCategory) {
      computedData = computedData.filter((el) =>
        el.categoryIds.includes(activeCategory.ecwid_id)
      )
    }

    return computedData
  }, [products, activeCategory])

  function createSlug(text) {
    return slugify(text, {
      replacement: "-", // replace spaces with replacement
      remove: /[*+~.()'"!:@?]/g, // regex to remove characters
      lower: true, // result in lower case
    })
  }

  function checkProducts(ecwid_id) {
    const items = products.filter((el) => el.categoryIds.includes(ecwid_id))
    return items.length > 0
  }

  const styles = {
    itemImage: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: 200,
    },
  }

  return (
    <Layout headerType="solid">
      <SeoComponent title={"shop"} description={""} />
      <div className="solid-header-page-spacer" />
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mb-md-0">
            <ul className="list-group list-group-flush">
              {categories.map((cat) => {
                if (cat.productCount === 0) return null

                if (!checkProducts(cat.ecwid_id)) return null

                return (
                  <li
                    className="list-group-item"
                    key={cat.ecwid_id}
                    style={{
                      cursor: "pointer",
                      color:
                        cat.ecwid_id === activeCategory.ecwid_id
                          ? "#7c0001"
                          : "",
                    }}
                    onClick={() => setActiveCategory(cat)}
                  >
                    {cat.name}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="col-md-9">
            <h5 className="mb-3">{activeCategory.name}</h5>

            {datatable.length == 0 && (
              <div className="col-12">
                <p className="text-center">No product found!</p>
              </div>
            )}

            <div className="row mb-5">
              {datatable.map((item) => (
                <div className="col-md-4 mb-3">
                  <div key={item.ecwid_id} className="bg-white shadow h-100">
                    <Link
                      to={`/${createSlug(item.name)}`}
                      className="text-dark"
                    >
                      <div
                        style={{
                          ...styles.itemImage,
                          backgroundImage: `url(${item.originalImageUrl})`,
                        }}
                      />
                      <div className="p-3">
                        <p>{item.name}</p>
                        <h5>{item.defaultDisplayedPriceFormatted}</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

// const Shop = ({ location }) => (
//   <Layout headerType="solid" location={location}>
//     <SeoComponent title="404: Not Found" />
//     <div className="solid-header-page-spacer" />
//     <div className="pb-10">
//       <div className="container">
//         <div className="row justify-content-center text-center">
//           <div className="col-lg-8">
//             <h1 className="mb-4">Sorry, this page does not exist.</h1>
//             <p className="mb-0">
//               Please check if the URL is entered correctly. If you think this is
//               an error, please contact us.
//             </p>
//             <Link to="/" className="mt-4 btn btn-primary">
//               Return Home
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   </Layout>
// )

export default Shop

export const shop = graphql`
  query shopQuery {
    allEcwidCategories {
      nodes {
        name
        ecwid_id
        productCount
      }
    }
    allEcwidProducts(filter: { enabled: { eq: true } }) {
      nodes {
        ecwid_id
        name
        categoryIds
        defaultDisplayedPriceFormatted
        originalImageUrl
      }
    }
  }
`
